import { useQuery } from '@apollo/client'
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

import { SanityParentProductFragment } from '../../../graphql/gatsby'
import {
  ConfigurableProductDetailsFragment,
  GetProductsByUrlKeyDocument,
  GetProductsByUrlKeyQuery,
  SimpleProductDetailsFragment,
} from '../../../graphql/magento'
import { ProductLabelStatus, SearchParentProductBySkuDocument } from '../../../graphql/search'
import { useLabelName } from '../../../hooks/useLabelName'
import { useRefetchOnChange } from '../../../hooks/useRefetchOnChange'
import useSiteMetadata from '../../../hooks/useSiteMetadata'
import { TokenContext } from '../../../layouts/context'
import { setAnalyticsEvent } from '../../../lib/analytics/state'
import { useDispensaryContext } from '../../../lib/dispensaries'
import {
  isSanityImageWithAssetId,
  SanityProductImages,
  SanityProductImagesHierarchy,
} from '../../../lib/products/images'
import { updateUrlHashForSku } from '../../../lib/products/utils/pdp'
import { useSearchLazyQuery } from '../../../lib/search'
import { imageUrlFor } from '../../../utils/image-url'
import Breadcrumbs from '../../Breadcrumbs'
import ItemImages from '../../products/details/images/ItemImages'
import Spinner from '../../Spinner'
import ItemDetails from './item_details/ItemDetails'
import { RelatedProducts } from './RelatedProducts'

export interface ItemContainerProps {
  className?: string
  pageContext?: {
    urlKey: string
  }
  sanityParentProduct?: SanityParentProductFragment | null
  sanityChildProducts?: SanityParentProductFragment['products'] | null
}

const takeIfConfigurableProduct = (
  item: NonNullable<NonNullable<GetProductsByUrlKeyQuery['products']>['items']>[number] | undefined,
): ConfigurableProductDetailsFragment | undefined =>
  item?.__typename === 'ConfigurableProduct' ? item : undefined

const ItemContainer: FC<ItemContainerProps> = ({
  pageContext = {
    urlKey: '',
  },
  className = '',
  sanityParentProduct,
  sanityChildProducts,
}) => {
  const token = useContext(TokenContext)
  const [selectedVariant, setSelectedVariant] = useState<
    SimpleProductDetailsFragment | null | undefined
  >(undefined)
  const [selectedChild, setSelectedChild] = useState<
    NonNullable<SanityParentProductFragment['products']>[number] | null | undefined
  >(undefined)
  const { data, networkStatus, refetch } = useQuery(GetProductsByUrlKeyDocument, {
    returnPartialData: true,
    context: { token },
    variables: { urlKey: pageContext.urlKey },
  })
  const dispensary = useDispensaryContext()
  const labelNameResult = useLabelName()
  const [executeParentProductBySkuSearch, { data: lazyData, called: lazyCalled }] =
    useSearchLazyQuery(SearchParentProductBySkuDocument, {
      notifyOnNetworkStatusChange: true,
    })

  useRefetchOnChange({ networkStatus, refetch }, [token])

  useEffect(() => {
    if (!selectedVariant || selectedVariant.__typename !== 'SimpleProduct') {
      return
    }
    const finalPrice = selectedVariant.price_tiers?.[0]?.final_price
    const currency = finalPrice?.currency
    const price = finalPrice?.value
    if (currency == null || price == null) {
      return
    }
    setAnalyticsEvent({
      event: 'view_item',
      currency,
      value: price,
      items: [
        {
          item_id: selectedVariant.sku || undefined,
          item_name: selectedVariant.name as string,
          currency,
          price,
        },
      ],
      app_context: dispensary ? 'dispensary_pdp' : 'pdp',
    })
  }, [selectedVariant, dispensary])

  const item = takeIfConfigurableProduct(data?.products?.items?.[0])

  const onVariantSelected = useCallback(
    (itemValue: string) => {
      updateUrlHashForSku(itemValue || null)
      if (itemValue) {
        setSelectedVariant(item?.variants?.find((ver) => ver?.product?.sku === itemValue)?.product)
        setSelectedChild(sanityChildProducts?.find((child) => child?.sku === itemValue))
      } else {
        setSelectedVariant(undefined)
      }
    },
    [item, sanityChildProducts],
  )

  const selectedVariantLabelStatus = useMemo(
    () =>
      selectedVariant?.sku
        ? (lazyData?.parentProductBySku?.products.edges.find(
            ({ node }) => node && node.sku === selectedVariant.sku,
          )?.node.labelStatus as ProductLabelStatus)
        : undefined,
    [selectedVariant?.sku, lazyData?.parentProductBySku?.products.edges],
  )

  const selectedVariantRetailPrice = useMemo(
    () =>
      selectedVariant?.sku
        ? lazyData?.parentProductBySku?.products.edges.find(
            ({ node }) => node && node.sku === selectedVariant.sku,
          )?.node.retailPrice
        : undefined,
    [selectedVariant?.sku, lazyData?.parentProductBySku?.products.edges],
  )

  // Conditionally search parent product by sku for label statuses
  useEffect(() => {
    if (!item) {
      return
    }

    const exactVariantSkus =
      item.variants?.reduce((acc: string[], variant) => {
        const variantProductSku = variant?.product?.sku
        if (variantProductSku) {
          acc.push(variantProductSku)
        }
        return acc
      }, []) || []

    // Verify there are variants to check label statuses
    if (
      labelNameResult._tag === 'Success' &&
      item.sku &&
      exactVariantSkus.length > 0 &&
      !lazyCalled
    ) {
      executeParentProductBySkuSearch({
        variables: {
          sku: item.sku,
          labelName: labelNameResult.labelName,
          includeLabel: true,
        },
      })
    } else if (
      labelNameResult._tag !== 'Success' &&
      item.sku &&
      exactVariantSkus.length > 0 &&
      !lazyCalled
    ) {
      // request parent product since we need retail price for each child product
      executeParentProductBySkuSearch({
        variables: {
          sku: item.sku,
          labelName: '',
          includeLabel: false,
        },
      })
    }
  }, [executeParentProductBySkuSearch, item, labelNameResult, lazyCalled])

  const selectedSanityProduct = selectedVariant
    ? sanityParentProduct?.products?.find((product) => product?.sku === selectedVariant.sku)
    : undefined
  const selectedVariantPrice = selectedSanityProduct?.prices?.retail?.amount ?? null
  const selectedVariantCurrency = selectedSanityProduct?.prices?.retail?.currency ?? null
  const selectedVariantImage =
    selectedSanityProduct && isSanityImageWithAssetId(selectedSanityProduct.mainImage)
      ? imageUrlFor(selectedSanityProduct.mainImage).width(1200).auto('format').url()
      : null

  // collect Sanity images for parent and child products
  const sanityImages = useMemo(
    (): SanityProductImagesHierarchy => ({
      parent: {
        mainImage:
          sanityParentProduct && isSanityImageWithAssetId(sanityParentProduct.image)
            ? {
                ...sanityParentProduct.image,
                // TODO use `alt` from Sanity when parent product schema updated
                alt: '',
              }
            : undefined,
      },
      children: (sanityParentProduct?.products ?? []).reduce((acc, product) => {
        const sku = product?.sku
        if (!sku) {
          return acc
        }

        acc.set(sku, {
          mainImage: isSanityImageWithAssetId(product.mainImage) ? product.mainImage : undefined,
          otherImages: (product.otherImages ?? []).filter(isSanityImageWithAssetId),
        })
        return acc
      }, new Map<string, SanityProductImages>()),
    }),
    [sanityParentProduct],
  )

  const { productionSiteBaseUrl } = useSiteMetadata() || {}

  if (item) {
    return (
      <>
        {item.name && (
          <Helmet>
            <title>{item.name}</title>
            <script type="application/ld+json">
              {JSON.stringify({
                '@context': 'https://schema.org/',
                '@type': 'Product',
                brand: sanityParentProduct?.brand,
                name: item.name,
                image: selectedVariantImage,
                sku: selectedVariant?.sku,
                description: sanityParentProduct?.metaDescription,
                offers: {
                  '@type': 'Offer',
                  url: `${productionSiteBaseUrl}/products/${pageContext.urlKey}/#${selectedVariant?.sku}`,
                  priceCurrency: selectedVariantCurrency,
                  price: selectedVariantPrice,
                  itemCondition: 'https://schema.org/NewCondition',
                  availability: 'https://schema.org/InStock',
                },
              })}
            </script>
          </Helmet>
        )}
        <div className={className}>
          <div className="item-container-inner">
            <section className="half-page">
              <StyledBreadcrumbs
                breadcrumbs={[
                  {
                    label: 'Products',
                    path: '/products',
                  },
                  {
                    label: item.name as string,
                  },
                ]}
              />
              <ItemImages
                item={item}
                selectedVariant={selectedVariant}
                sanityImages={sanityImages}
              />
            </section>
            <section className="half-page">
              <ItemDetails
                item={item}
                sanityParentProduct={sanityParentProduct}
                sanityChildProduct={selectedChild}
                selectedVariant={selectedVariant}
                onVariantSelected={onVariantSelected}
                variantLabelStatus={selectedVariantLabelStatus}
                variantRetailPrice={selectedVariantRetailPrice}
              />
            </section>
          </div>
          {sanityParentProduct?.relatedProducts &&
            sanityParentProduct.relatedProducts.length > 0 && (
              <RelatedProducts
                relatedProducts={{
                  title: sanityParentProduct?.relatedProductsTitle,
                  subtitle: sanityParentProduct?.relatedProductsSubtitle,
                  products: sanityParentProduct?.relatedProducts,
                }}
              />
            )}
        </div>
      </>
    )
  }

  return (
    <div style={{ background: '#fff', padding: '3rem' }}>
      <Spinner loading />
    </div>
  )
}

const StyledItemContainer = styled(ItemContainer)(({ theme }) => ({
  backgroundColor: theme.colors.grayscale.white,
  '.item-container-inner': {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    width: '100%',
  },
  '.half-page': {
    display: 'flex',
    flexBasis: '50%',
    flexWrap: 'wrap',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.colors.coolGray.cool250}`,
  },
  '@media(min-width: 600px)': {
    '.item-container-inner': {
      flexDirection: 'row',
    },
    '.half-page': {
      minHeight: '100%',
      flexBasis: '50%',
    },
  },
}))

const StyledBreadcrumbs = styled(Breadcrumbs)`
  background: ${({ theme }) => theme.colors.coolGray.cool250};
  flex-basis: 100%;
`

export default StyledItemContainer
